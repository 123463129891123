import { Box, Card, CardActions, CardContent, CardHeader, Hidden } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  GRID_TREE_DATA_GROUPING_FIELD,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { setMemberDialog } from '../ducks/group';
import { GroupMember } from '../types/GroupMember';
import { sortRows } from '../utils/sortUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { StatsDashboard } from './StatsDashboard';
import { useAppDispatch, useAppSelector } from '../hooks';
import { MuiDataGrid } from './MUIDataGrid';
import { TableControls } from './TableControls';
import { GroupMemberFilterMenu } from './GroupMemberFilterMenu';
import { SortProperty } from '../types/SortProperty';
import { SortableProperty } from '../types/SortableProperty';
import { MUIDataGrid, MUIDataGridColumn, usersColumns } from '../constants/MUIDataGrid';
import { getActionsMenuProps, getColumnCellRenderer, getDefaultColumnDefinition } from '../utils/MUIDataGridUtils';
import { ActionsMenu } from './ActionsMenu';
import { resetClientIds } from '../ducks/viewerSlice';
import { changeGroup } from '../ducks/currentUserSlice';
import { fetchGroups } from '../ducks/groups';

const getTableControls = ({
  rows,
  sortState,
  sortableProperties,
  handleSort,
}: {
  rows: (GroupMember & { groupName?: string })[];
  sortState: SortProperty[];
  sortableProperties: SortableProperty[];
  handleSort: (sortProperties: SortProperty[]) => void;
}): React.FC => {
  const users = rows.filter(({ groupName }) => !groupName);
  const TableControlsComp: React.FC<any> = () => (
    <TableControls
      filterRows={<GroupMemberFilterMenu count={users.length} />}
      sortProperties={sortState}
      sortableProperties={sortableProperties}
      onSort={handleSort}
    />
  );

  return TableControlsComp;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    background: '#F5F5F5',
  },
  button: {
    color: theme.palette.text.primary,
  },
  memberCards: {
    flex: 1,
    paddingBottom: '70px',
  },
  memberCard: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  memberCardHeader: { paddingBottom: '0' },
  memberCardActions: { paddingTop: '0' },
  memberCardContent: {
    paddingBottom: '0',
    paddingTop: '0',
  },
  memberCardContentLine: {
    color: theme.palette.text.secondary,
  },
  groupNameCell: {
    cursor: 'pointer',
  },
}));

interface Props {
  loading: boolean;
  members: (GroupMember & { groupName?: string })[];
  statsAndStatus?: boolean;
  table: MUIDataGrid.AllUsers | MUIDataGrid.Users;
}

export const GroupMembersTable: React.FC<Props> = ({ loading, members, statsAndStatus = false, table }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();

  const { availableDealers: dealers = [] } = useAppSelector((state) => state.currentUser);

  const [sortState, setSortState] = React.useState<SortProperty[]>([]);
  const sortableProperties: SortableProperty[] = [
    { i18nKey: I18nKeys.TableHeaderName, property: 'name' },
    { i18nKey: I18nKeys.TableHeaderEmail, property: 'email' },
    { i18nKey: I18nKeys.TableHeaderDealers, property: 'dealers' },
    { i18nKey: I18nKeys.TableHeaderPermissions, property: 'permissions' },
    { i18nKey: I18nKeys.TableHeaderStatus, property: 'status' },
  ];

  const stats = [];
  if (statsAndStatus) {
    const totalMembers = members.length;
    const activeMembers = members.filter((member) =>
      ['CONFIRMED', 'RESET_REQUIRED'].includes(member.status || ''),
    ).length;
    const inactiveMembers = members.filter((member) => member.status === 'FORCE_CHANGE_PASSWORD').length;

    stats.push({ i18nKey: I18nKeys.StatsTotalMembers, value: totalMembers });
    stats.push({ i18nKey: I18nKeys.StatsActiveMembers, value: activeMembers });
    stats.push({ i18nKey: I18nKeys.StatsInactiveMembers, value: inactiveMembers });
  }

  const columns: GridColDef[] = useMemo(
    () =>
      usersColumns
        .filter((column) => {
          if ([MUIDataGridColumn.Dealers, MUIDataGridColumn.Status, MUIDataGridColumn.Actions].includes(column))
            return table !== MUIDataGrid.AllUsers;
          if (column === MUIDataGridColumn.Status) return statsAndStatus;
          return true;
        })
        .map((column: MUIDataGridColumn) =>
          getDefaultColumnDefinition(
            {
              table,
              dealers,
              showGroupMemberMenu: true,
              editable: false,
            },
            column,
            classes,
            dispatch,
            t,
          ),
        ),
    [table, dealers, t, statsAndStatus, dispatch],
  );

  const handleSort = (sortProperties: SortProperty[]): void => {
    sortRows(members, sortProperties);
    setSortState(sortProperties);
  };

  const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
    (params: GridRowParams<any>) => {
      if (table !== MUIDataGrid.Users) return;
      const { row: member } = params;
      dispatch(setMemberDialog(member));
      dispatch(openDialog({ dialog: Dialogs.User }));
    },
    [table, dispatch],
  );

  const onCellClick = React.useCallback<GridEventListener<'cellClick'>>(
    (params: GridCellParams) => {
      const { row: { groupId = '' } = {}, field } = params;
      if (!groupId || field !== [MUIDataGrid.AllUsers, MUIDataGridColumn.Name].join('-')) return;
      dispatch(resetClientIds());
      dispatch(changeGroup(groupId));
      dispatch(fetchGroups());
    },
    [dispatch],
  );

  return (
    <div className={classes.root}>
      {statsAndStatus && <StatsDashboard stats={stats} />}
      <Hidden smDown>
        <MuiDataGrid
          loading={loading}
          rows={members}
          columns={columns}
          apiRef={apiRef}
          getRowId={({ path = [], username }) => path.join(':') || username}
          autoHeight
          slots={{
            toolbar: getTableControls({
              rows: members,
              sortState,
              sortableProperties,
              handleSort,
            }),
          }}
          onCellClick={onCellClick}
          onRowClick={onRowClick}
          treeData={table === MUIDataGrid.AllUsers}
          getTreeDataPath={({ path, username }) => path || [username]}
          groupingColDef={getDefaultColumnDefinition(
            {
              table,
              dealers,
              showGroupMemberMenu: true,
              editable: false,
            },
            GRID_TREE_DATA_GROUPING_FIELD,
            classes,
            dispatch,
            t,
          )}
        />
      </Hidden>
      <Hidden smUp>
        <Box style={{ flexDirection: 'column', height: '100%' }}>
          {members.map((member) => {
            const gridCellRendererParams = {
              row: member,
            } as GridRenderCellParams;

            const [DealersCell, PermissionsCell, ActionsCell] = [
              MUIDataGridColumn.Dealers,
              MUIDataGridColumn.Permissions,
              MUIDataGridColumn.Actions,
            ].map((column) =>
              getColumnCellRenderer(
                { table, dealers, showGroupMemberMenu: false, editable: false },
                column,
                dispatch,
                t,
              )?.renderCell?.(gridCellRendererParams),
            );

            return (
              <Card className={classes.memberCard} key={member.username}>
                <CardHeader
                  className={classes.memberCardHeader}
                  action={
                    <ActionsMenu
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getActionsMenuProps(
                        { table, dealers, showGroupMemberMenu: true, editable: false },
                        member,
                        dispatch,
                        t,
                      )}
                    />
                  }
                  title={member.name}
                />
                <CardContent className={classes.memberCardContent}>
                  <Box className={classes.memberCardContentLine}>{member.email}</Box>
                </CardContent>
                <CardContent className={classes.memberCardContent}>
                  <Box className={classes.memberCardContentLine}>{member.phone}</Box>
                </CardContent>
                <CardContent className={classes.memberCardContent}>{DealersCell}</CardContent>
                <CardContent className={classes.memberCardContent}>{PermissionsCell}</CardContent>
                <CardActions disableSpacing className={classes.memberCardActions}>
                  {ActionsCell}
                </CardActions>
              </Card>
            );
          })}
        </Box>
      </Hidden>
    </div>
  );
};
